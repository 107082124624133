import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';

import Pix from '../../assets/pix.png';

import { find } from '../../services/application';

import { Container, Content, PixImage, Config } from './styles';
import { Connect } from '../../components/Connect';
import { getCredentials } from '../../infra/config/storage';

function Display() {
  const [identifier, setIdentifier] = useState('');
  const [openConnect, setOpenConnect] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (!openConnect) {
      const { token } = getCredentials();
      setIdentifier(token);
    }
  }, [openConnect]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (openConnect || !identifier) {
        return;
      }

      try {
        const { qrcode } = await find();
        setCode(qrcode);
      } catch (error) {
        setCode('');
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [openConnect, identifier]);

  return (
    <Container>
      <Content>
        <PixImage src={Pix} alt="Pix" />
        {code ? (
          <>
            <QRCode value={code} />
            <span>Escaneie este qr code para realizar o pagamento</span>
          </>
        ) : (
          <span>Aguardando qr code...</span>
        )}
      </Content>
      <Config onClick={() => setOpenConnect(true)}>
        <PowerOutlinedIcon />
      </Config>
      <Connect openConnect={openConnect} setOpenConnect={setOpenConnect} />
    </Container>
  );
}

export { Display };
