import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';

import { Container, Content, Input, Button } from './styles';
import { getCredentials, setCredentials } from '../../infra/config/storage';

function Connect({ openConnect, setOpenConnect }) {
  const [identifier, setIdentifier] = useState('');

  const handleIdentifier = event => {
    setIdentifier(event.target.value);
  };

  const handleConnect = () => {
    setCredentials({ token: identifier });
    setOpenConnect(false);
  };

  useEffect(() => {
    if (openConnect) {
      const { token } = getCredentials();
      setIdentifier(token);
    }
  }, [openConnect]);

  return (
    <Drawer
      anchor="bottom"
      open={openConnect}
      onClose={() => setOpenConnect(false)}
    >
      <Container>
        <Content>
          <Input>
            <span>identificador</span>
            <input type="text" value={identifier} onChange={handleIdentifier} />
          </Input>
          <Button onClick={handleConnect}>Conectar</Button>
        </Content>
      </Container>
    </Drawer>
  );
}

export { Connect };
