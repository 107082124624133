const types = Object.freeze({
  CREDENTIALS: '@pix:credentials:1.0.0',
});

export const setCredentials = credentials => {
  localStorage.setItem(types.CREDENTIALS, JSON.stringify(credentials));
};

export const getCredentials = () => {
  const credentials = localStorage.getItem(types.CREDENTIALS);
  if (credentials) {
    return JSON.parse(credentials);
  }
  return { token: '' };
};
