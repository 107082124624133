import { urlBase, userCredentials } from './base';

export const find = async () => {
  const { token } = userCredentials();

  const url = `${urlBase}/find/${token}`;

  const response = await fetch(url, { method: 'GET' });

  return response.json();
};
