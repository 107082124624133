import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh / 3);

  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const Input = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;

  span {
    font-size: 0.7rem;
    font-weight: 500;
    color: #333;
    text-transform: uppercase;
  }

  input {
    width: 100%;
    border-radius: 0.5rem;
    border: 2px solid #bdbdbd;
    padding: 0.5rem;

    font-size: 0.85rem;
    font-weight: 500;
    color: #333;

    outline: 0;

    transition: border 0.2s;

    &:focus {
      border: 2px solid #26a69a;
    }
  }
`;

export const Button = styled.button`
  width: 100%;

  border-radius: 0.5rem;
  border: 0;
  padding: 0.75rem;

  background: #26a69a;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;

  transition: background 0.2s;

  &:hover {
    background: #1b7d6f;
  }

  cursor: pointer;
`;
