import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;

  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: 1rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
`;

export const PixImage = styled.img`
  width: 100%;
  max-width: 600px;
  margin-bottom: 3rem;
`;

export const Config = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;

  background: transparent;
  border: 0;
  color: #333;
  font-size: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: background 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  cursor: pointer;
`;
